<template>
<v-card>
    <v-dialog v-if="dialogShow" v-model="dialogShow" width="40%" persistent hide-overlay>
        <v-expansion-panels focusable>
            <v-expansion-panel v-for="item in optionList" :key="item.OptID" class="pt-0 pb-0">
                <v-expansion-panel-header @click="getQuerys(item)" style="min-height: 0px; height: 0px">
                    {{ item.OptName }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list dense>
                        <v-list-item-group color="primary">
                            <v-list-item v-for="item2 in querys" :key="item2.QryID" @click="openModalFilter(item2)" style="min-height: 25px">
                                <v-icon left> mdi-checkbox-marked-circle </v-icon>
                                {{ item2.QryNameReport }}
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-dialog>

    <v-dialog v-if="dialogFilter" v-model="dialogFilter" width="30%" persistent>
        <v-card>
            <v-container>
                <v-row style="margin-top: 5px">
                    <v-col cols="12" style="margin-top: -10px" v-for="(item, index) in itemsParameter" :key="item.CfdID">
                        <!-- {{item}} -->
                        <s-date :label="item.CfdParameterName" v-if="item.TypeParameterAbbreviation == 's-date'" v-model="parametergen[index]"></s-date>

                        <s-text :label="item.CfdParameterName" v-if="item.TypeParameterAbbreviation == 's-text'" v-model="parametergen[index]"></s-text>

                        <s-select-definition :def="item.CfdDefID" :label="item.CfdParameterName" v-if="item.TypeParameterAbbreviation == 's-select-definition'" v-model="parametergen[index]"></s-select-definition>

                        <s-select v-if="item.TypeParameterAbbreviation == 'component'" :label="item.CfdParameterName" :item-text="item.CfdItemText" :item-value="item.CfdItemValue" v-model="parametergen[index]" full :items="itemsComponent">
                        </s-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn x-small elevation="0" color="info" @click="runView()" style="text-transform: none">Visualizar</v-btn>
                        <v-btn x-small elevation="0" color="success" @click="downloadReport()" style="text-transform: none">Descargar</v-btn>
                        <v-btn x-small elevation="0" @click="closeModalFilter()" style="text-transform: none">Cancelar</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>

    <v-dialog v-if="dialogReport" v-model="dialogReport" width="90%" height="80%" transition="dialog-bottom-transition">
        <!-- <v-card> -->
        <s-toolbar :label="report.QryNameReport" color="#BAB6B5" dark close @close="closeModalReport()">
        </s-toolbar>
        <!-- </v-card> -->

        <v-card outlined>
            <v-row style="margin: auto; margin-top: -20px">
                <v-col cols="12">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                </v-col>
                <v-col cols="12" style="margin-top: -20px">
                    <v-data-table height="400" show-group-by item-key="Line" :items="items" :headers="headers" :search="search" class="elevation-1 table" dense v-model="selectedRow" @click:row="rowClick">
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>

    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error al realizar busqueda <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-card>
</template>

<script>
import _sOption from "@/services/Security/OptionService.js";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import _sQryConfigurationDetailService from "@/services/QueryManager/QryConfigurationDetailService";
import _sAuth from "@/services/AuthService";

export default {
    components: {},
    data() {
        return {
            optionList: [],
            show: false,
            querys: [],
            dialogReport: false,
            dialogFilter: false,
            dialogShow: true,
            report: {},

            items: [],
            headers: [],
            search: "",
            processing: false,
            messageProcessing: "",
            itemsParameter: [],
            parametergen: [],
            usrID: this.$fun.getUserID(),
            selectedRow: [],
            paramsTMP: [],
            itemsComponent: []
        };
    },

    watch: {
        //console.log("holaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        paramsTMP() {
            //console.log("watch",paramsTMP)
            /*  if (this.paramsTMP.length > 0) {

                this.paramsTMP.forEach((element) => {
                    if (
                        element.CfdQuerySql !== null &&
                        element.TypeParameterAbbreviation == "component"
                    ) {
                        _sQryConfigurationService
                            .execuetSQL({
                                    TransactSQL: element.CfdQuerySql,
                                },
                                this.$fun.getUserID()
                            )
                            .then((resp) => {

this.itemsComponent = resp.data
                            });
                    }
                });

            }

this.itemsParameter = this.paramsTMP;

this.dialogFilter = true; */

        },
    },

    methods: {
        initialize() {
            _sAuth.initialize(this.usrID).then((response) => {
                this.optionList = response.data.UserMenu;
            });
        },

        rowClick: function (item, row) {
            if (item !== undefined) {
                this.selectedRow = [item];
            }
        },

        getQuerys(item) {
            _sQryConfigurationService.list(item, this.$fun.getUserID()).then((resp) => {
                if (resp.status == 200) {
                    this.querys = resp.data;
                }
            });
        },

        /* paaaramsTMP() {
            //console.log("watch",paramsTMP)
            if (this.paramsTMP.length > 0) {
                this.paramsTMP.forEach((element) => {
                    if (
                        element.CfdQuerySql !== null &&
                        element.TypeParameterAbbreviation == "component"
                    ) {
                        _sQryConfigurationService
                            .execuetSQL({
                                    TransactSQL: element.CfdQuerySql,
                                },
                                this.$fun.getUserID()
                            )
                            .then((resp) => {

this.itemsComponent = resp.data
                            });
                    }
                });

            }

console.log("itemsComponent",this.itemsComponent)

//this.itemsParameter = this.paramsTMP;

        }, */

        openModalFilter(item) {
            this.report = {};

            console.log("openModalFilter", item);
            this.report = item;

            _sQryConfigurationDetailService
                .list(this.report, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        this.paramsTMP = r.data;

                        var component = this.paramsTMP.find(x => x.TypeParameterAbbreviation == 'component')

						if (component !== null && component !== undefined) {
                            if (component.CfdQuerySql !== null) {
                                _sQryConfigurationService
                                    .execuetSQL({
                                        TransactSQL: component.CfdQuerySql
                                    }, this.$fun.getUserID())
                                    .then(resp => {
                                        this.itemsComponent = resp.data
										this.itemsParameter = this.paramsTMP;
										//this.dialogFilter = true; 
                                    })
                            }

                        }else{
							this.itemsParameter = this.paramsTMP;
							
						}

						this.dialogFilter = true; 

                        //var resp = await this.paaaramsTMP()

                        //console.log("paramsTMP", this.paramsTMP)

                        /* if (this.paramsTMP.length > 0) {

                                                    this.paramsTMP.forEach(element => {

                                                        if (element.CfdQuerySql !== null && element.TypeParameterAbbreviation == 'component') {
                                                            _sQryConfigurationService
                                                                .execuetSQL({
                                                                    TransactSQL: element.CfdQuerySql
                                                                }, this.$fun.getUserID())
                                                                .then(resp => {
                                                                    console.log("itemsAll")
                                                                    element.itemsAll = resp.data
                                                                })
                                                        }

                                                    });
                                                } */

                        //this.itemsParameter = this.paramsTMP;

                    }
                });
        },

        closeModalFilter() {
            this.itemsParameter = [];
            this.dialogFilter = false;
        },

        closeModalReport() {
            this.items = [];
            this.headers = [];
            this.dialogReport = false;
        },

        order() {
            let i = 0;
            this.items.map((e) => {
                i++;
                e.Line = i;
            });
        },

        runView() {
            this.dialogReport = true;

            this.report.QryNameProcedure = "";
            this.report.QryParamsArrayValue = this.parametergen.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure =
                this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

            this.processing = true;
            _sQryConfigurationService.querygeneral(this.report, this.$fun.getUserID()).then(
                (resp) => {
                    if (resp.status == 200) {
                        this.processing = false;
                        this.items = resp.data;
                        for (var i = 0; i < Object.keys(this.items[0]).length; i++) {
                            this.headers.splice(i, i, {
                                text: Object.keys(this.items[0])[i],
                                value: Object.keys(this.items[0])[i],
                                width: "150",
                                sortable: false,
                            });
                        }

                        this.order();
                    }
                },
                (e) => {
                    this.error = true;
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                }
            );
        },

        downloadReport() {
            this.QryNameProcedure = "";
            this.report.QryParamsArrayValue = this.parametergen.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure =
                this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

            this.processing = true;
            _sQryConfigurationService.downloadexcel(this.report, this.$fun.getUserID()).then(
                (r) => {
                    if (r.status == 200) {
                        this.processing = false;

                        this.$fun.downloadFile(
                            r.data,
                            this.$const.TypeFile.EXCEL,
                            this.report.QryNameReport
                        );
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                }
            );
        },
    },

    created() {
        this.initialize();
    },
};
</script>
