import Service from "../Service";

const resource = "option/";

export default {
  get(optID, requestID) {
    return Service.post(
      resource,
      {},
      {
        params: { optID: optID, requestID: requestID },
      }
    );
  },

  list(secStatus, requestID) {
    return Service.post(
      resource + "list",
      {},
      {
        params: { secStatus: secStatus, requestID: requestID },
      }
    );
  },
  save(opt, requestID) {
    return Service.post(resource + "save", opt, {
      params: { requestID: requestID },
    });
  },
  savemenu(list, requestID) {
    return Service.post(resource + "savemenu", list, {
      params: { requestID: requestID },
    });
  },
};
